import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { graphql } from 'gatsby'

// Generic
import Container from '../../shared/Container/Container'
import HtmlContent from '../../shared/HtmlContent/HtmlContent'
import Image from '../../shared/Image/Image'
import Heading from '../../shared/Heading/Heading'



// Utils
import getBreakpointWithoutUnit from '../../../utils/getBreakpointWithoutUnit'

const QuizHero = (props) => {
  return (
      <section
        className='c-hero h-full'
      >
        <div className='c-hero__inner'>
          <Container className='flex md:flex-col w-full'>
            <div className='c-hero__content'>
              <Heading
                className='h1'
                {...props.data.primary.hero_title}
              />
              <div className='c-hero__body space-y-4'>
                {props.data?.primary?.hero_intro?.html && (
                  <HtmlContent
                    html={props.data.primary.hero_intro.html}
                    className='text-xl sm:text-2xl mt-6 c-prose'
                  />
                )}
                {props.data?.primary?.hero_body?.html && (
                  <HtmlContent
                    html={props.data.primary.hero_body.html}
                    className='hidden sm:block text-lg mt-6 c-prose'
                  />
                )}
              </div>
              {props.additionalContent}
          </div>
          </Container>
          {props.data.primary?.hero_image?.url && (
            <div
              className={classNames('c-hero__img z-aboveAll', {
                'text-primary': props.data.primary.hero_image_background === 'Primary',
                'text-secondary-dark': props.data.primary.hero_image_background === 'Secondary'
              })}
            >
              <Image
                className='relative z-10'
                fallbackAlt={props.data.primary.hero_title.text}
                lazyload={false}
                sizes={[
                  `(max-width: ${getBreakpointWithoutUnit('sm') - 1}px) 85vw`,
                  `(max-width: ${getBreakpointWithoutUnit('xl') - 1}px) 50vw`,
                  '686px'
                ]}
                {...props.data.primary.hero_image}
              />
            </div>
          )}
        </div>
      </section>
  )
}

QuizHero.propTypes = {
  data: PropTypes.object.isRequired,
  additionalContent: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
    PropTypes.string
  ])
}

export default QuizHero

export const query = graphql`
  fragment QuizHero on PrismicPage {
    data {
      body {
        ... on PrismicPageBodyHero {
          id
          slice_type
          primary {
            hero_title {
              html
              text
              raw
            }
            hero_intro {
              html
            }
            hero_body {
              html
            }
            hero_image {
              ...Image
            }
            hero_image_background
            hero_illustration {
              ...Image
            }
            hero_show_logo
            logo_group {
              ...LogoGroup
            }
          }
          items {
            hero_cta_label {
              text
            }
            hero_cta_link {
              ...Link
            }
            cta_style
          }
        }
      }
    }
  }
`
