import React from 'react'
import { graphql } from 'gatsby'
import { withPreview } from 'gatsby-source-prismic'

// Layout
import Seo from '../components/layout/Seo/Seo';

// Views
import ProjectPhaseView from '../components/views/ProjectPhaseView/ProjectPhaseView';

// Utils
import getCustomRobotsFromTags from '../utils/getCustomRobotsFromTags';

function ProjectPhaseViewTemplate({
    data,
    location
}) {
    const seo = {
        title: data.prismicProjectPhase.data.title ? data.prismicProjectPhase.data.title.text : 'Missing Title',
        customTitle: data.prismicProjectPhase.data.meta_title,
        description: data.prismicProjectPhase.data.meta_description ? data.prismicProjectPhase.data.meta_description.text : data.prismicProjectPhase.data.intro ? data.prismicProjectPhase.data.intro.text : 'Missing Meta Description or Intro',
        socialImageUrl: data.prismicProjectPhase.data.social_image ? data.prismicProjectPhase.data.social_image.url : null,
        mainLogoUrl: data.prismicProjectPhase.data.main_logo ? data.prismicProjectPhase.data.main_logo.url : null,
    }

    const tags = data.prismicProjectPhase.tags;
    const robots = getCustomRobotsFromTags(tags);

    return (
        <>
            <Seo
                title={seo.title}
                customTitle={seo.customTitle}
                description={seo.description}
                image={seo.socialImageUrl || seo.mainLogoUrl}
                slug={location.pathname}
                robots={robots}
            />

            <ProjectPhaseView data={data} />
        </>
    )
}

export default withPreview(ProjectPhaseViewTemplate);

export const PageQuery = graphql`
    query ProjectPhaseViewTemplateQuery (
        $uid: String!
    ) {
        prismicProjectPhase (
            uid: {
                eq: $uid
            }
        ) {
            uid
            tags
            data {
                title {
                    text
                    html
                    raw
                }
                meta_title
                meta_description {
                    text
                }
                project_group_name {
                    text
                }
                headline {
                    text
                    html
                    raw
                }
                main_logo {
                    ...Image
                }
                main_video {
                    ...Embed
                }
                short_tag_line {
                    text
                }
                description_image {
                    ...Image
                }
                description_text {
                    html
                }

                the_team {
                    team_member_image {
                        ...Image
                    }

                    team_member_name {
                        text
                    }

                    team_member_desc {
                        text
                        html
                    }

                    team_member_github {
                        ...Link
                    }

                    team_member_linkedin {
                        ...Link
                    }
                }

                technologies_image {
                    ...Image
                }
                technologies_description {
                    text
                    html
                }

                challenges_faced {
                    text
                    html
                }

                faq {
                    question {
                        text
                    }

                    answer {
                        text
                        html
                    }
                }
            }
        }
    }
`;
