import React, { useState } from "react"
import { graphql } from "gatsby"
import { withPreview } from "gatsby-source-prismic"

// Layout
import Seo from "../components/layout/Seo/Seo"

//Views
import QuizLanding from "../components/views/SortingQuizView/QuizLanding"
import QuizContent from "../components/views/SortingQuizView/QuizContent"
import QuizResults from "../components/views/SortingQuizView/QuizResults"

// Utils
import formatQuestionContent from '../utils/formatQuizContent'
import getCustomRobotsFromTags from '../utils/getCustomRobotsFromTags'

const SortingQuizTemplate = ({ data, location }) => {
  const [pageToDisplay, setPageToDisplay] = useState('landing');
  const [scores, setScores] = useState({});

  const tags = data.prismicSortingquiz.tags;
  const robots = getCustomRobotsFromTags(tags);

  //  Type-check the `pageToDisplay` and `scores`
  //  as a way of documentation and
  //  because... javascript.
  const alowedPageToDisplayValues = ['landing', 'questions', 'results'];
  if (
    !alowedPageToDisplayValues.includes(pageToDisplay) ||
    typeof pageToDisplay !== 'string' ||
    typeof scores !== 'object'
  ) {
    throw new Error('pageToDisplay must be a string of value "landing", "questions", or "results"');
  }

  const quizData = data.prismicSortingquiz.data;

  // The page won't work without the quizData being set,
  // so throw an error if it's not set.
  if (!quizData) {
    throw new Error('Quiz data is missing');
  }

  const questions = quizData.questions;
  const formattedQuestions = formatQuestionContent(questions);

  const results = quizData.results;

  const resetQuiz = () => {
    setPageToDisplay('landing');
    setScores({});
  }

  return (
    <>
      <Seo
        title={quizData.title.text}
        description={quizData.description.text}
        image={quizData.landing_image.src}
        slug={location.pathname}
        robots={robots}
      />

      {pageToDisplay === 'landing' &&
        <QuizLanding
          title={quizData.title}
          description={quizData.description}
          landing_image={quizData.landing_image}
          setPageToDisplay={setPageToDisplay}
        />}
      {pageToDisplay === 'questions' &&
        <QuizContent
          formattedQuestions={formattedQuestions}
          setScores={setScores}
          setPageToDisplay={setPageToDisplay}
          content={{}}
        />}
      {pageToDisplay === 'results' &&
        <QuizResults
          numberOfQuestions={formattedQuestions.length}
          courses={results}
          scores={scores}
          resetQuiz={resetQuiz}
        />}
    </>
  )
}

export default withPreview(SortingQuizTemplate)

export const PageQuery = graphql`
  query SortingQuizTemplateQuery($uid: String!) {
    prismicSortingquiz(uid: { eq: $uid }) {
      uid
      tags
      data {
        title {
          html
          text
          raw
        }

        description {
          html
          text
          raw
        }

        landing_image {
          ...Image
        }

        questions {
          title1 {
            html
            text
            raw
          }

          software {
            html
            text
            raw
          }

          cloud {
            html
            text
            raw
          }

          data {
            html
            text
            raw
          }

          c_sharp {
            html
            text
            raw
          }
        }

        results {
          course_image {
            ...Image
          }

          course_slug {
            html
            text
            raw
          }

          course_title {
            html
            text
            raw
          }

          course_information {
            html
            text
            raw
          }

          course_link {
            ...Link
          }

          career_information {
            html
            text
            raw
          }
        }
      }
    }
  }
`;
