import React from 'react'
import PropTypes from 'prop-types'

// Generic
import Button from '../../shared/Button/Button'
import QuizHero from '../../elements/Hero/QuizHero'

const QuizLanding = ({ title, description, landing_image, setPageToDisplay }) => {
  const goToQuestionsPage = () => { setPageToDisplay('questions') }

  const quizHeroData = {
    primary: {
      hero_title: title,
      hero_intro: description,
      hero_image: landing_image,
      hero_image_background: "Secondary",
    },
  }
  return (
      <QuizHero
        data={quizHeroData}
        additionalContent={
          <div className="mt-8 sm:mt-12">
            <Button
              icon={true}
              color="primary"
              className="mb-4 font-bold text-2xl px-8 py-6 mr-4 cursor-pointer z-aboveAll"
              onClick={goToQuestionsPage}
            >
              Start Quiz
            </Button>
          </div>
        }
      />
  );
}

QuizLanding.propTypes = {
  title: PropTypes.object.isRequired,
  description: PropTypes.object.isRequired,
  landing_image: PropTypes.object.isRequired,
  setPageToDisplay: PropTypes.func.isRequired
}

export default QuizLanding
