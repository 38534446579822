import React from 'react'
import { graphql } from 'gatsby'
import { withPreview } from 'gatsby-source-prismic'

// Layout
import Seo from '../components/layout/Seo/Seo'

// Views
import CourseView from '../components/views/CourseView/CourseView'

const CourseViewDevelopmentTemplate = ({ data, location }) => {
  let courseData = data.prismicCourseDevelopment;  
  return (
    <>
      <Seo
        title={courseData.data.title.text}
        customTitle={courseData.data.meta_title}
        description={courseData.data.meta_description.text || courseData.data.course_intro.text}
        image={courseData.data.social_image.url}
        slug={location.pathname}
      />
      <CourseView data={courseData.data} />
    </>
  )
}

export default withPreview(CourseViewDevelopmentTemplate)

export const PageQuery = graphql`
  query CourseViewDevelopmentTemplateQuery (
    $uid: String!
  ) {
    prismicCourseDevelopment (
      uid: {
        eq: $uid
      }
    ) {
      uid
      data {
        title {
          text
          html
          raw
        }
        meta_title
        meta_description {
          text
        }
        social_image {
          url
        }
        apply_cta_link {
          ...Link
        }
        curriculum_form_guid
        logo_group {
          ...LogoGroup
        }
        course_intro {
          html
          text
        }
        course_illustration {
          ...Image
        }
        course_header {
          text
        }
        course_body {
          html
        }
        price_heading {
          text
        }
        course_start_date(formatString: "Do MMMM YYYY")
        start_date_body {
          html
        }
        course_location {
          text
        }
        course_duration {
          text
        }
        duration_body {
          html
        }
        course_duration_days {
          text
        }
        course_duration_times {
          text
        }
        comparison_table_heading {
          html
        }
        comparison_table_body {
          text
          html
        }
        compare_against {
          text
        }
        compare_against_price {
          text
        }
        compare_against_extra_info {
          text
        }
        compare_to {
          text
        }
        compare_to_price {
          text
        }
        compare_to_extra_info {
          text
        }
        comparison_table_content {
          content_label {
            text
          }

          does_compare_against_have
          does_compare_to_have
        }
        course_price_total
        price_body {
          html
        }
        price_total {
          text
        }
        start_date_bullets {
          html
          raw
          text
        }
        duration_bullets {
          html
          raw
          text
        }
        price_bullets {
          html
          raw
          text
        }
        course_price_breakdown {
          course_price_breakdown_price
          course_price_breakdown_description {
            text
          }
          course_price_breakdown_extra_info {
            html
          }
        }
        course_video_embed {
          ...Embed
        }
        course_video_embed_custom_title
        course_image_embed {
          ...Image
        }
        course_features {
          course_features_icon {
            ...Image
          }
          course_features_header {
            text
            raw
          }
          course_features_body {
            html
          }
        }
        course_curriculum_title {
          text
        }
        course_curriculum_tabs {
          course_curriculum_tab_value {
            text
          }
          course_curriculum_tab_header {
            text
          }
          course_curriculum_tab_body {
            html
          }
          course_curriculum_tab_footnote {
            html
          }
        }
        course_curriculum_additional_info_grid {
          course_curriculum_additional_info_grid_icon {
            ...Image
          }
          course_curriculum_additional_info_grid_header {
            text
            raw
          }
          course_curriculum_additional_info_grid_body {
            html
          }
        }
        course_dates_title {
          text
        }
        course_dates {
          course_dates_location {
            text
          }
          course_dates_extra_label {
            text
          }
          course_dates_date(formatString: "Do MMMM YYYY")
          course_dates_display_apply_now_button
        }
        course_finance_title {
          text
        }
        course_finance_rows_with_icon {
          course_finance_row_icon {
            ...Image
          }
          course_finance_row_body {
            html
          }
          cta_label
          cta_link {
            ...Link
          }
        }
        course_finance_rows {
          body {
            html
          }
          cta_label
          cta_link {
            ...Link
          }
        }
        course_testimonial_carousel {
          isBroken
          document {
            ...TestimonialCarousel
          }
        }
        course_faqs_title {
          text
        }
        course_faqs_tabs {
          course_faqs_header {
            text
          }
          course_faqs_body {
            html
          }
        }
        course_faqs_cta_label {
          text
        }
        course_faqs_cta_link {
          ...Link
        }
      }
    }
  }
`
