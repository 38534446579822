import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import Container from "../../shared/Container/Container";
import Edge from "../../shared/Edge/Edge";
import jump from "jump.js";

export default function ApplicationPage({ data }) {
  const [hasStarted, setHasStarted] = useState(false);
  const [location, setLocation] = useState(null);

  const whichCourseRef = useRef(null);

  useEffect(() => {
    whichCourseRef.current = document.getElementById("which-course-is-for-you");
  }, []);

  const adjustLocation = (location) => {
    setLocation(location);
    setHasStarted(true);

    const element = document.querySelector("#which-course-is-for-you");
    if (element) {
      jump(element);
    }
  };

  return (
    <>
      <section
        className={classNames(
          `c-hero ${
            hasStarted
              ? "c-hero__remove_edge_margin"
              : "c-hero__remove_edge_margin_fully mb-0"
          }`
        )}
      >
        <div className="c-hero__inner">
          <Container className="w-full max-w-none">
            <div className="c-hero__content w-full max-w-full">
              <h1>1. Choose your location</h1>
              <p className="pl-0 md:pl-18 mt-4 text-xl">
                Let us know where you’ll be accessing our courses from, so we
                can show you whats on and when text.
              </p>

              <div className="grid md:hidden mt-6">
                <section className="w-full grid grid-cols-3 text-center sm:text-right gap-4 sm:gap-0">
                  <div>
                    <div className="font-bold text-lightGrey uppercase mb-1">
                      UK
                    </div>
                    <ul className="text-lg sm:text-xl">
                      <li className="mb-1" onClick={() => adjustLocation('ENGLAND')}>England</li>
                      <li className="mb-1" onClick={() => adjustLocation('SCOTLAND')}>Scotland</li>
                      <li className="mb-1" onClick={() => adjustLocation('NI')}>Northern Ireland</li>
                      <li className="mb-1" onClick={() => adjustLocation('WALES')}>Wales</li>
                    </ul>
                  </div>

                  <div>
                    <div className="font-bold text-lightGrey uppercase mb-1">
                      Europe
                    </div>
                    <ul className="text-lg sm:text-xl">
                      <li className="mb-1" onClick={() => adjustLocation('GERMANY')}>Germany</li>
                      <li className="mb-1" onClick={() => adjustLocation('NL')}>Netherlands</li>
                      <li className="mb-1" onClick={() => adjustLocation('IRELAND')}>Ireland</li>
                      <li className="mb-1" onClick={() => adjustLocation('SPAIN')}>Spain</li>
                    </ul>
                  </div>

                  <div>
                    <div className="font-bold text-lightGrey uppercase mb-1">
                      Remote
                    </div>
                    <ul className="text-lg sm:text-xl">
                      <li className="mb-1" onClick={() => adjustLocation('ONLINE')}>Online</li>
                    </ul>
                  </div>
                </section>
              </div>

              <div className="w-full hidden md:grid text-left md:text-right mt-12">
                <div className="hidden md:grid md:grid-cols-3 font-bold text-lightGrey uppercase">
                  <span>UK</span>
                  <span>EUROPE</span>
                  <span>REMOTE</span>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-3 mt-4 text-2xl gap-y-6">
                  <span onClick={() => adjustLocation("ENGLAND")}>England</span>
                  <span onClick={() => adjustLocation("GERMANY")}>Germany</span>
                  <span onClick={() => adjustLocation("ONLINE")}>Online</span>
                  <span onClick={() => adjustLocation("SCOTLAND")}>
                    Scotland
                  </span>
                  <span onClick={() => adjustLocation("NL")}>Netherlands</span>
                  <span></span>

                  <span onClick={() => adjustLocation("NI")}>
                    Northern Ireland
                  </span>
                  <span onClick={() => adjustLocation("IRELAND")}>Ireland</span>
                  <span></span>

                  <span onClick={() => adjustLocation("WALES")}>Wales</span>
                  <span onClick={() => adjustLocation("SPAIN")}>Spain</span>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </section>
      {hasStarted && (
        <>
          <section
            className="bg-primary text-white pb-12"
            id="which-course-is-for-you"
            ref={whichCourseRef}
          >
            <h2 className="font-normal text-center py-12">
              2. Which course is for you?
            </h2>
            <ul
              className="
          grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3
          gap-6
          w-2/3
          mx-auto
        "
            >
              {Array(6)
                .fill()
                .map((_, i) => (
                  <li
                    className="
                      bg-white
                      text-black
                      p-6
                    "
                  >
                    <h2 className="text-3xl font-normal mb-2">Title</h2>
                    <span className="inline-block bg-secondary-light text-black px-4 rounded-md text-sm">
                      Manchester
                    </span>
                    <p className="text-xs mt-2">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Quisque id augue magna. Ut molestie augue nec ex fringilla
                      augue sollicitudin quis. Fusce viverra odio a pharetra
                      posuere. Nunc eu velit diam. Suspendisse eleifend metus
                      nec ante iaculis, non vehicula leo porta. Nullam sagittis
                      metus sed magna volutpat tempor. Donec tortor lacus,
                      facilisis at rutrum nec, molestie sit amet risus. Vivamus
                      et convallis diam.
                    </p>

                    <ul className="my-6">
                      <li className="bg-white border-2 border-black text-black flex flex-row">
                        <div className="w-full  p-4">
                          <strong>12th September 2024</strong>
                          <p>places avaliable</p>
                        </div>

                        <div className="border-l-2 border-black w-auto grid items-center  p-4">
                          {`>`}
                        </div>
                      </li>
                      <li className="bg-white border-2 border-primary text-black flex flex-row mt-2 cursor-pointer shadow-md">
                        <div className="w-full  p-4">
                          <strong>12th September 2024</strong>
                          <p className="text-primary">places selling <strong className="font-bold">FAST!</strong></p>
                        </div>

                        <div className="border-l-2 border-primary bg-primary text-white w-auto grid items-center  p-4">
                          {`>`}
                        </div>
                      </li>
                    </ul>

                    <ul className="flex flex-row justify-around mt-4 text-sm">
                      <li>X £6.50</li>
                      <li>X 13 Weeks, Full Time</li>
                    </ul>
                  </li>
                ))}
            </ul>
          </section>
          <Edge
            location="top"
            direction="right"
            outerClassName="text-primary"
            innerClassName="text-white"
          />
          <section className="py-12">
            <h2 className="font-normal pl-12 pb-12">3. About you</h2>
            <ul className="grid grid-cols-1 md:grid-cols-applicationPageFormGrid px-12 gap-12 items-start">
              <li className="block w-full">Form here...</li>
              <li className="block w-full">
                <span className="block mb-2">Course Summary</span>

                <div className="border-2 border-whiteSmoke p-10">
                  <h2 className="font-normal text-3xl mb-2">
                    Software Development Bootcamp
                  </h2>
                  <span className="inline-block bg-secondary-light text-black px-4 mt-2 rounded-md text-sm">
                    Manchester
                  </span>

                  <div className="flex flex-row bg-white shadow-md mt-6">
                    <div className="block bg-primary h-full w-12 p-6">Hey</div>
                    <div className="p-6">
                      <strong>15th Jan 2024</strong>
                    </div>
                  </div>

                  <ul className="flex flex-row justify-around mt-12">
                    <li>X £6.50</li>
                    <li>X 13 Weeks, Full Time</li>
                  </ul>
                </div>
              </li>
            </ul>
          </section>
        </>
      )}
    </>
  );
}
