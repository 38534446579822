import React from 'react'
import Button from "../../shared/Button/Button";
import CourseImage from './CourseImage';

const TopResultCard = ({coursesWithPercentages, coursesMatchingTopPercentage, resetQuiz}) => {
    const {
        percentage: topPercentage,
        course_title: topCourse,
        course_information,
        course_link,
        career_information,
        course_image
      } = coursesWithPercentages;

      const styles = {
        javascript: 'green-light',
        data: 'purple-dark',
        java: 'tertiary-dark',
        c_sharp: 'purple-600',
      };

      let topCourseSlug = topCourse.text.split(' ')[0].toLowerCase().trim();
      if (topCourseSlug === 'c#') {
        topCourseSlug = 'c_sharp';
      }
    return (
      <div className="flex flex-col w-screen md:w-1/2 px-4 mr-4  md:p-6 h-1/2 ml-4 z-10">
        <h1 className={`text-4xl font-medium p-4 text-white bg-${styles[topCourseSlug]}`}>
          Based on your answers we think you're most like a {topCourse.text}!
        </h1>
        <section className="bg-gray-lighter shadow-xl p-4 flex-col md:flex-row">
          <h2 className={`text-4xl font-medium text-${styles[topCourseSlug]}`}>{topCourse.text}</h2>
          <p className="p-4">{course_information.text}</p>
          <div className="bg-whiteSmoke flex flex-col xl:flex-row">
            <div>
              <h3 className={`text-xl text-${styles[topCourseSlug]} font-medium p-4`}>
                Career Opportunities
              </h3>
              <p className={`xl:border-r-2 border-${styles[topCourseSlug]} p-4 pt-0`}>
                {career_information.text}
              </p>
            </div>
            <div className="flex flex-col justify-center items-center">
              <CourseImage
                course_image={course_image.url}
                course_title={topCourse.text}
              />
              <Button
                icon={true}
                iconDirection={"right"}
                url={course_link.url}
                className={`bg-${styles[topCourseSlug]} rounded-md underline text-white p-4 m-4`}
                target={"_blank"}
              >
                {`Become a ${topCourse.text}`}
              </Button>
            </div>
          </div>
        </section>
      </div>
    );
        }


        export default TopResultCard
