import React from "react";
import Seo from "../components/layout/Seo/Seo";
import { withPreview } from "gatsby-source-prismic";
import ApplicationPage from "../components/views/ApplicationPage/ApplicationPage";

function ApplicationPageTemplate(props) {
  const data = props.data;
  return (
    <>
      <Seo
        title={"Title"}
        // customTitle={data.prismicCareer.data.meta_title}
        description={"Description"}
        image={""}
        slug={"/rest"}
        robots={"noindex nofollow"}
      />
      <ApplicationPage data={data.prismicApplyPage.data} />
    </>
  );
}

export default withPreview(ApplicationPageTemplate);

export const PageQuery = graphql`
  query ApplicationPageTemplateQuery(
    $uid: String!
    $masterLocations: [String!]!
  ) {
    prismicApplyPage(uid: {
      eq: $uid
    }
    ) {
      uid
      tags
      data {
        title {
          html
          text
        }
      }
    }

    allPrismicMasterLocation(
      filter: {
        uid: {
          in: $masterLocations
        }
      }
    ) {
      nodes {
        uid
        data {
          locations {
            location_image {
              ...Image
            }
            location_name {
              text
            }
            location_description {
              text
            }
          }
        }
      }
    }

    # allPrismicMasterLocation(
    #   filter: {
    #     uid: {
    #       in: $masterLocations
    #     }
    #   }
    # ) {
    #   nodes {
    #     uid
    #     data {
    #       locations {
    #         location_name {
    #           text
    #         }
    #         course_dates_document {
    #           document {
    #             ... on PrismicMasterCourseDates {
    #               uid
    #               data {
    #                 course_title {
    #                   text
    #                 }
    #               }
    #             }
    #           }
    #         }
    #       }
    #     }
    #   }
    # }
  }
`;
