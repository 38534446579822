import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import { withPreview } from "gatsby-source-prismic";

// Layout
import Seo from "../components/layout/Seo/Seo";

// Views
import ProjectPhaseListView from "../components/views/ProjectPhaseListView/ProjectPhaseListView";

const ProjectPhaseListViewTemplate = ({ data, pageContext, location }) => {
  const allProjects = data.allPrismicProjectPhase.nodes;
  const title = data.prismicProjectPhaseList.data.title;
  const intro = data.prismicProjectPhaseList.data.intro;
  const headlineImage = data.prismicProjectPhaseList.data.headline_image;
  const faqs = data.prismicProjectPhaseList.data.project_phase_list_faqs_list;
  const [bootcampSearch, setBootcampSearch] = useState("choose");
  const [cohortSearch, setCohortSearch] = useState("choose");
  const [filteredProjects, setFilteredProjects] = useState(allProjects);
  const [filtering, setFiltering] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  return (
    <>
    <Seo
      title='Project Phase List'
      description='Discover the range of products that our graduates work on as part of their portfolio projects while on the Northcoders Bootcamp.'
      slug={location.pathname}
    />

    <ProjectPhaseListView
      title={title}
      intro={intro}
      allProjects={allProjects}
      headlineImage={headlineImage}
      faqs={faqs}
      filteredProjects={filteredProjects}
      setFilteredProjects={setFilteredProjects}
      bootcampSearch={bootcampSearch}
      setBootcampSearch={setBootcampSearch}
      cohortSearch={cohortSearch}
      setCohortSearch={setCohortSearch}
      filtering={filtering}
      setFiltering={setFiltering}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
    />
    </>
  )
};

export default withPreview(ProjectPhaseListViewTemplate);

export const PageQuery = graphql`
  query ProjectPhaseListViewTemplateQuery(
    $uid: String!
    $allProjectPhases: [String!]!
  ) {
    prismicProjectPhaseList(uid: { eq: $uid }) {
      uid
      tags

      data {
        title {
          html
          text
          raw
        }
        intro {
          html
        }

        headline_image {
          ...Image
        }

        project_phase_list_faqs_list {
          faqs_list_question {
            text
            html
          }
          faqs_list_answer {
            text
            html
          }
        }
      }
    }

    allPrismicProjectPhase(filter: { uid: { in: $allProjectPhases } }) {
      nodes {
        ...ProjectPhase
      }
    }
  }
`;
