/**
 * Formats the question content into nicer object keys
 * @param  {Array} questions[]
 * @return {Array} formattedQuestions[]
 */
const formatQuestionContent = (questions) => {
    return questions.map((question) => {
        const formattedQuestionData = {}

        formattedQuestionData.question = question.title1.text
        formattedQuestionData.answers = [
            { text: question.software.text, course_slug: 'software' },
            { text: question.cloud.text, course_slug: 'cloud' },
            { text: question.data.text, course_slug: 'data' },
            { text: question.c_sharp.text, course_slug: 'c_sharp' }
        ]
        return formattedQuestionData
    })
}

export default formatQuestionContent
