import { Container } from '@material-ui/core';
import React, {useEffect, useState} from 'react'
import Faqs from '../../elements/Faqs/Faqs';
import Edge from '../../shared/Edge/Edge';
import Button from '../../shared/Button/Button';
import HtmlContent from '../../shared/HtmlContent/HtmlContent';
import Image from '../../shared/Image/Image';
import DarkRedLogo from '../../../assets/img/logo-dark-red.svg'
const PROJECTS_LIMIT = 5;

export default function ProjectPhaseListView({
  title,
  intro,
  allProjects,
  headlineImage,
  faqs,
  filteredProjects,
  setFilteredProjects,
  bootcampSearch,
  setBootcampSearch,
  cohortSearch,
  setCohortSearch,
  filtering,
  setFiltering,
  currentPage,
  setCurrentPage,
}) {
  useEffect(() => {
    // On mount, limit the filteredProjects to the first PROJECTS_LIMIT
    setFilteredProjects(allProjects.slice(0, PROJECTS_LIMIT));
  }, []);
  useEffect(() => {
    // Whenever the page has changed, we want to (depending on the page)
    // either append the next 25 projects to our filteredProjects array,
    // or reset the filteredProjects array to the first 25 projects.
    if (filtering) {
      return;
    }

    if (currentPage === 1 &&
        (bootcampSearch === "choose" && cohortSearch === "choose")) {
      // We're on the first page, we want to reset the filteredProjects array
      // to the first 25 projects.
      setFilteredProjects(allProjects.slice(0, PROJECTS_LIMIT));
      return;
    }

    // We're on a page that is not the first page, we want to append the next
    // 25 projects to our filteredProjects array.
    const indexOfLastProject = currentPage * PROJECTS_LIMIT;
    const indexOfFirstProject = indexOfLastProject - PROJECTS_LIMIT;
    // If we're filtering already, we want to get the next 25 projects from
    // our filteredProjects array, otherwise we want to get the next 25 projects
    // from our allProjects array.
    let nextProjects = [];
    if (bootcampSearch === "choose" && cohortSearch === "choose") {
      nextProjects = allProjects.slice(
        indexOfFirstProject,
        indexOfLastProject
      );
    } else {
      nextProjects = filteredProjects.slice(
        indexOfFirstProject,
        indexOfLastProject
      );
    }

    // We want to append these nextProjects to our currentProjects array.
    let newProjects = [];

    if (bootcampSearch === "choose" && cohortSearch === "choose") {
      newProjects = [...filteredProjects, ...nextProjects];
    } else {
      newProjects = [...nextProjects];
    }

    setFilteredProjects(newProjects);
  }, [currentPage]);

  // We want to loop through all projects, and find any tags that are
  // bootcamp-X or cohort-X. We want these all within an array so we can
  // filter through them in the select box. We do not want duplicates.
  const bootcamps = [];
  const cohorts = [];

  allProjects.forEach((project) => {
    const tags = project.tags;
    tags.forEach((tag) => {
      if (tag.includes("bootcamp-")) {
        const bootcamp = tag.replace("bootcamp-", "");
        if (!bootcamps.includes(bootcamp)) {
          bootcamps.push(bootcamp);
        }
      }
      if (tag.includes("cohort-")) {
        const cohort = tag.replace("cohort-", "");
        if (!cohorts.includes(cohort)) {
          cohorts.push(cohort);
        }
      }
    });
  });

  useEffect(() => {
    if (bootcampSearch === "choose" && cohortSearch === "choose") {
      setFilteredProjects(allProjects.slice(0, PROJECTS_LIMIT));
      return;
    }
  }, [bootcampSearch, cohortSearch]);

  const filterProjects = () => {
    if (bootcampSearch === "choose" && cohortSearch === "choose") {
      setFilteredProjects(allProjects.slice(0, PROJECTS_LIMIT));
      return;
    }
    setFiltering(true);
    const filteredProjects = allProjects.filter((project) => {
      // Sometimes, the bootcampSearch will be filled out
      // but the cohortSearch will not be. Same with the other way round,
      // in this case, we only want to check the tags on the filled out one.
      // If both are filled out, we want to check both.
      if (bootcampSearch !== "choose" && cohortSearch !== "choose") {
        return (
          project.tags.includes(`bootcamp-${bootcampSearch}`) &&
          project.tags.includes(`cohort-${cohortSearch}`)
        );
      }

      if (bootcampSearch !== "choose" && cohortSearch === "choose") {
        return project.tags.includes(`bootcamp-${bootcampSearch}`);
      }

      if (cohortSearch !== "choose" && bootcampSearch === "choose") {
        return project.tags.includes(`cohort-${cohortSearch}`);
      }
    });

    // We want to reset the page back to 1, as we're filtering.
    setCurrentPage(1);
    setFilteredProjects(filteredProjects);
    setFiltering(false);
  };
  return (
    <div className="lg:px-0">
      <div>
        <div className="c-hero c-hero__remove_edge_margin w-full">
          <div className="c-hero__inner">
            <Container className="flex md:flex-col w-full">
              <div className="c-hero__content">
                <h1 className="text-5xl font-medium">
                  {title.text.length === 0 ? 'Bootcamp Projects' : title.text}
                </h1>
                <HtmlContent
                  html={intro.html}
                  className="c-prose text-lg mt-4"
                />
              </div>
            </Container>

            <div className="c-hero__img text-primary">
              <Image
                fallbackAlt={headlineImage.alt || "Headline image"}
                className="relative"
                {...headlineImage}
              />
            </div>
          </div>
        </div>
      </div>

      <div>
        <section className="bg-primary relative">
          <div className='z-highest relative block'>
            <Container width="thinner" className="py-10 sm:py-20">
              <div className="text-center text-white">
                <h1 className="text-4xl font-bold">Projects</h1>
                <p className="w-3/4 mx-auto mt-2">
                  Discover the range of products that our graduates work on as
                  part of their portfolio projects while on the Northcoders
                  Bootcamp.
                </p>
                <div
                  className="
                  w-full lg:w-3/4 mx-auto
                grid
                grid-cols-3
                gap-6
                mb-6 mt-6
                text-black
                c-hubspot-form
              "
                >
                  <select
                    className="px-2"
                    onChange={(e) => setBootcampSearch(e.target.value)}
                  >
                    <option value={"choose"}>Select Bootcamp Type</option>
                    <option value={"software"}>
                      Software Developer Bootcamp
                    </option>
                    <option value={"data"}>Data Engineering Bootcamp</option>
                    <option value={"cloud"}>Cloud Engineering Bootcamp</option>
                  </select>
                    <select
                      className="px-2"
                      onChange={(e) => setCohortSearch(e.target.value)}
                    >
                      <option value={"choose"}>Select Cohort</option>
                      {cohorts.map((cohort) => (
                        <option value={cohort}>{cohort}</option>
                      ))}
                    </select>
                  <Button color="tertiary" icon onClick={() => filterProjects()} className='cursor-pointer'>
                    Sort
                  </Button>
                </div>
              </div>
              <ul
                className="
            grid
            xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-3
            gap-6
            h-full
                    "
              >
                {filteredProjects.map((project) => {
                  const teamMembers = project.data.the_team;
                  return (
                    <li key={project.uid} className="bg-white shadow-md">
                      <div className="flex flex-col h-full justify-between">
                      <Image {...project.data.description_image} />
                        <div className="p-4 flex flex-col justify-between h-full">
                          <div>
                            <h2 className="font-medium text-3xl">{project.data.title.text}</h2>
                            <p className="c-prose text-base mt-2">{project.data.short_tag_line.text}</p>
                          </div>
                          <div>
                            <h3 className="text-primary text-lg mt-6">Team</h3>
                            <ul className="font-medium">
                              {teamMembers.length > 0 &&
                                teamMembers.map((teamMember, i) => {
                                  const isLast = i === teamMembers.length - 1;
                                  const name = teamMember.team_member_name.text;
                                  if (isLast) {
                                    return name;
                                  } else {
                                    return `${name}, `;
                                  }
                                })}
                            </ul>
                          <Button
                            color='secondary'
                            className='mt-4 py-4 cursor-pointer hover:bg-primary hover:text-white transition-colors duration-75'
                            url={`/project-phase/${project.uid}`}
                            target={'_blank'}
                            linkType='internal'
                            icon
                          >
                            View Project
                            </Button>
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </Container>
            <div className="text-white font-bold flex flex-row justify-center gap-12 pb-6 text-xl">
              {
                // We want to show the next button if there are any more results
                // after the current page.
                // allProjects.length > currentPage * PROJECTS_LIMIT && (
                //   <Button
                //     color="tertiary"
                //     onClick={() => setCurrentPage(currentPage + 1)}
                //     className="cursor-pointer"
                //     icon
                //   >
                //     Load More
                //   </Button>
                // )
                bootcampSearch !== "choose" || cohortSearch !== "choose" ? (
                    filteredProjects.length > currentPage * PROJECTS_LIMIT && (
                      <Button
                        color="tertiary"
                        onClick={() => setCurrentPage(currentPage + 1)}
                        className="cursor-pointer"
                        icon
                      >
                        Load More
                      </Button>
                    )
                ) : (
                  allProjects.length > currentPage * PROJECTS_LIMIT && (
                    <Button
                      color="tertiary"
                      onClick={() => setCurrentPage(currentPage + 1)}
                      className="cursor-pointer"
                      icon
                    >
                      Load More
                    </Button>
                  )
                )
              }
            </div>
          </div>

            <div className='absolute z-0 top-0 hidden md:block'>
              <DarkRedLogo />
            </div>
        </section>
        <Edge
          location="top"
          direction="right"
          outerClassName={"text-primary"}
          innerClassName={"text-white"}
        />
          <Container className={'my-12 w-3/4 mx-auto'}>
            <h2 className="mb-6">FAQs</h2>
        <Faqs
                      canAnimate
                      className='mb-12'
                      items={faqs.map((faq) => {
                        return {
                          header: faq.faqs_list_question.text,
                          body: (
                            <HtmlContent
                              html={faq.faqs_list_answer.html}
                              className='c-prose'
                            />
                          )
                        }
                      })}
                    />
                    </Container>
      </div>
    </div>
  );
}
