import React, { useState } from "react"
import PropTypes from "prop-types"


const QuizContent = ({ formattedQuestions, setScores, setPageToDisplay }) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
  const currentQuestion = formattedQuestions[currentQuestionIndex]

  const handleAnswerSelection = (course) => {
    setScores((currAnswerTally) => {
      const updatedScore =
        currAnswerTally[course] ? currAnswerTally[course] + 1 : 1

      const updatedAnswerTally = {
        ...currAnswerTally,
        [course] : updatedScore
      }
      return updatedAnswerTally;
    })

    const hasAnsweredLastQuestion = currentQuestionIndex === formattedQuestions.length - 1;

    if (hasAnsweredLastQuestion) {
      setCurrentQuestionIndex(0)
      setPageToDisplay('results');
    } else {
      setCurrentQuestionIndex((previousIndex) => {
        return previousIndex + 1
      })
    }
  }

  const colourSchemes = [
    {
      chevron: 'bg-green-light', // #41d581
      rightBackground: 'bg-green-lighter', // #c0f8d1
      leftBackground: 'bg-secondary-light', // #f8f163
    },
    {
      chevron: 'bg-secondary-light', // #f8f163
      rightBackground: 'bg-tertiary-lighter', // #bff7f4
      leftBackground: 'bg-tertiary-dark', // #5ec9cc
    },
    {
      chevron: 'bg-purple-light', // #9494f4
      rightBackground: 'bg-secondary-light', // #f8f163
      leftBackground: 'bg-purple-light', // #9494f4
    },
    {
      chevron: 'bg-green-lighter', // #c0f8d1
      rightBackground: 'bg-purple-lighter', // #dbdbfd
      leftBackground: 'bg-tertiary-lighter', // #bff7f4
    },
    {
      chevron: 'bg-tertiary-dark', // #5ec9cc
      rightBackground: 'bg-secondary-lighter', // #f9f4b8
      leftBackground: 'bg-tertiary-lighter', // #bff7f4
    },
  ]

  const themeIndex = (currentQuestionIndex % colourSchemes.length);

  const {
    rightBackground,
    leftBackground
  } = colourSchemes[themeIndex]

  return (
    <div className="pt-24 flex  min-h-screen">
      <section className={`w-full flex flex-col md:flex-row`}>
        <div className={`bg-repeatingNCLogo${themeIndex} flex items-center justify-center min-h-questionMobile h-1/2 p-3 md:h-full md:w-7/12 relative ${leftBackground}`}>
          <h4 className="bg-white p-6 md:text-5xl w-11/12 z-10">
            {currentQuestion.question}
          </h4>
          <div className={`${rightBackground} absolute right-0 w-1/6 h-full z-0 hidden md:block `}></div>
        </div>
        <div className={`${rightBackground} flex flex-col items-center justify-center h-full w-full md:w-5/12 relative`}>

          <form className="flex flex-col text-2xl mt-12 md:text-xl font-medium w-4/5">
            {currentQuestion.answers.map((answer, index) => {
              return (
                <label
                  key={answer.text + index}
                  className="p-5 md:p-4 border-b-2 border-white hover:bg-white"
                >
                  {answer.text}
                  <input
                    type="radio"
                    value={answer.text}
                    id={`answer-${index}-${answer.text}`}
                    name="answer"
                    onChange={() => {
                      handleAnswerSelection(answer.course_slug);
                    }}
                    className="appearance-none"
                  />
                </label>
              );
            })}
          </form>
          <div className={`${leftBackground} mb-12 md:absolute md:right-0 md:top-0 mt-8 md:mr-12 md:mt-10 px-3 py-1 md:p-4 md:rounded-full font-medium text-xl`}>
            {currentQuestionIndex + 1}/{formattedQuestions.length}
          </div>
        </div>
      </section>
    </div>
  );
}

QuizContent.propTypes = {
  formattedQuestions: PropTypes.array.isRequired,
  setScores: PropTypes.func.isRequired,
  setPageToDisplay: PropTypes.func.isRequired,
  content: PropTypes.object.isRequired,
}

export default QuizContent
