import React from 'react';
import Button from '../../shared/Button/Button';
import CourseImage from './CourseImage';

const SecondaryResultCard = ({
  course_slug,
  course_title,
  course_information,
  course_link,
  percentage,
  course_image,
  index
}) => {
  const styles = {
    software: 'green-light',
    data: 'purple-dark',
    cloud: 'tertiary-dark',
    c_sharp: 'secondary-dark',
  };
  let courseSlug = course_slug.toLowerCase().trim();
  if (courseSlug === 'c#') {
    courseSlug = 'c_sharp';
  }

  return (
    <div className='m-4 mt-10 sm:mt-0 mr-2 z-10'>
      <h2
        // Even though purple-dark is within the styles object, it's not a valid class name
        // for Tailwind, and is not used anywhere else, so in production it will be purged.
        // This is a workaround to prevent it from being purged.
        className={`text-xl p-2 w-full inline-block text-white bg-${styles[courseSlug]} ${courseSlug === 'data' ? 'bg-purple-dark' : ''}`}
      >
        {index === 1
          ? `You're a lot like a ${course_title}`
          : `You're a little bit like a ${course_title}`}
      </h2>
      <section
        className="bg-gray-lighter shadow-xl mb-4 flex flex-col xl:flex-row items-center"
        key={course_slug}
      >
        <p className="p-4">{course_information}</p>
        <div className="flex flex-col justify-center items-center">
          <CourseImage
            course_image={course_image}
            course_title={course_title}
          />
          <Button
            icon={true}
            iconDirection={"right"}
            url={course_link}
            className={`text-white p-5 m-4 rounded-md underline bg-${styles[courseSlug]}`}
            target={"_blank"}
          >
            Become a {course_title}
          </Button>
        </div>
      </section>
    </div>
  );};

export default SecondaryResultCard;
