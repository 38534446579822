import React from 'react'
import PropTypes from 'prop-types'


import Button from "../../shared/Button/Button.js"
import TopResultCard from '../../elements/QuizResults/TopResultCard.js';
import SecondaryResultCard from '../../elements/QuizResults/SecondaryResultCard.js';


const QuizResults = ({ courses, scores, resetQuiz, numberOfQuestions }) => {
  const coursesWithPercentages = courses.map(course => {
    const courseSlug = course.course_slug.text.toLowerCase();
    const courseScore = scores[courseSlug] || 0;
    const percentage = Math.round(courseScore / numberOfQuestions * 100);
    return {
      ...course,
      percentage: percentage
    }
  }).sort((a,b) => b.percentage - a.percentage);

  const {
    percentage: topPercentage
  } = coursesWithPercentages[0];
  
  const coursesMatchingTopPercentage = coursesWithPercentages.filter(({percentage}) => percentage === topPercentage);

  return (
    <div className="c-quiz-hero pt-24 px-4 flex flex-wrap bg-confettiBackground -mt-6 bg-white justify-center">
      <section className="max-w-8xl flex flex-col md:flex-row  items-start place-content-center bg-white">
        <TopResultCard
          coursesWithPercentages={coursesWithPercentages[0]}
          coursesMatchingTopPercentage={coursesMatchingTopPercentage}
          resetQuiz={resetQuiz}
        />
        <div className="flex flex-col w-screen m-auto md:w-1/2 pt-10 p-6">
          {coursesWithPercentages.map(
            (
              {
                course_slug,
                course_title,
                course_information,
                course_link,
                percentage,
                course_image,
              },
              i
            ) => {
              if (i > 0 && i < 3)
                return (
                  <SecondaryResultCard
                    key={course_slug.text}
                    course_image={course_image.url}
                    course_slug={course_slug.text}
                    course_title={course_title.text}
                    course_information={course_information.text}
                    course_link={course_link.url}
                    percentage={percentage}
                    index={i}
                  />
                );
            }
          )}
        </div>
      </section>
      
      <p className='z-10 w-1/2 pb-10 mt-4 text-center text-tertiary-darkest font-bold'>
        We hope you enjoyed playing the quiz, please note that this is just a suggestion and we recommend reading the curriculum and doing your own research as well.
      </p>

      <div className="z-10 w-10/12 flex flex-col md:flex-row justify-around mb-6">
        <Button
          icon={true}
          iconDirection={"left"}
          className="lg:border-2 mb-6 md:mb-0 text-white lg:hover:bg-white lg:hover:text-teal-500 bg-ncRed hover:bg-primary-hover cursor-pointer lg:border-white h-2 lg:bg-transparent"
          onClick={resetQuiz}
        >
          Retake Quiz
        </Button>
        <Button
          icon={true}
          className="bg-white border-2 md:border-white border-dark-purple text-black h-2 hover:bg-offWhite"
          url={"/our-courses"}
          target={"_blank"}
        >
          View all our courses
        </Button>
      </div>
    </div>
  );
}

QuizResults.propTypes = {
  courses: PropTypes.array.isRequired,
  scores: PropTypes.object.isRequired,
  resetQuiz: PropTypes.func.isRequired,
  numberOfQuestions: PropTypes.number.isRequired
}

export default QuizResults