import React from 'react'
import { graphql } from 'gatsby'
import { withPreview } from 'gatsby-source-prismic'

// Layout
import Seo from '../components/layout/Seo/Seo'

// Content Components
import PageElements from '../components/elements/PageElements'

const PageTemplate = ({ data, location, ...props }) => {
  data.prismicPage.data.body.forEach((slice, slideIndex) => {
    // `hero_blocks` are almost part of `hero` element, but we need to tell the
    // `hero` element that they're there to add a modifier class to the `hero`
    // component so it sizes correctly
    if (slice.slice_type === 'hero_blocks') {
      // Find the first `hero` slice, which the className will be added to
      const heroIndex = data.prismicPage.data.body.findIndex((findSlice) => {
        return findSlice.slice_type === 'hero'
    })

      // Check there is a `hero` slice to add the class to
      if (heroIndex > -1) {
        data.prismicPage.data.body[heroIndex].hasBlocks = true
      }
    }
  })

  let robots = data.prismicPage.data.meta_robots;

  return (
    <>
      <Seo
        title={data.prismicPage.data.title.text}
        customTitle={data.prismicPage.data.meta_title}
        description={data.prismicPage.data.meta_description.text}
        image={data.prismicPage.data.social_image.url}
        slug={location.pathname}
        robots={robots}
      />
      <PageElements
        slices={data.prismicPage.data.body}
        location={location}
      />
    </>
  )
}

export default withPreview(PageTemplate)

export const PageQuery = graphql`
  query PageTemplateQuery($uid: String!) {
    prismicPage(uid: { eq: $uid }) {
      uid
      data {
        title {
          text
        }
        meta_title
        meta_description {
          text
        }
        meta_robots
        meta_fields {
          metafield_key
          metafield_value {
            html
            text
          }
          metafield_image {
            ...Image
          }
        }
        social_image {
          url
        }
      }
      ...BusinessThreeCol
      ...BusinessUpClose
      ...CareersList
      ...Clients
      ...ContactLocations
      ...ContactTwoCol
      ...CommunityGrid
      ...CourseCovered
      ...CourseDates
      ...CourseHowItWorks
      ...CoursesTwoUp
      ...FacebookPixel
      ...FiftyFifty
      ...FiftyFiftyCollage
      ...FinancePlans
      ...Hero
      ...HeroBlocks
      ...HeroMinimal
      ...InfoBlock
      ...InfoGrid
      ...LatestBlogPosts
      ...MirrorCTA
      ...PageFaqs
      ...PageHubspotForm
      ...PageTestimonialCarousel
      ...PodcastList
      ...PreFooter
      ...TeamGrid
      ...VideoEmbed
      ...VideoSideBySide
      ...WhyCarousel
      ...Paragraph
      ...DropdownContent
    }
  }
`
